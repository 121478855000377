import React, { useState, useRef } from "react";
import { List, X } from "react-bootstrap-icons";
import { StaticImage } from "gatsby-plugin-image";

export const Navbar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(false);
  const navRef = useRef<HTMLElement>();
  const NavLink: React.FC<{ to: string }> = ({ to, children }) => (
    <a
      href={to}
      className="block text-lg md:text-xl m-1 px-8 py-4 xl:mx-1 xl:px-4 xl:py-1 rounded hover:bg-primary-500 focus:bg-primary-500  duration-300"
      onClick={onClick}
    >
      {children}
    </a>
  );

  return (
    <div className="fixed top-0 left-0 w-full z-30 duration-300">
      <nav
        ref={navRef}
        className={`sticky top-0 z-30 min-w-full text-black xl:flex xl:justify-between bg-white dark:bg-gray-900 duration-500`}
      >
        <div className="flex items-center justify-between xl:justify-start px-4 py-2">
          <a
            href="/#home"
            className="py-1 px-3 flex items-center justify-items-start rounded fill-current stroke-current hover:bg-primary-500 focus:bg-primary-500 focus:outline-none duration-300"
            aria-label="Home"
          >
            <StaticImage
              alt="Heidekojoten Wolf"
              className="h-10 w-10"
              objectFit="contain"
              src="../images/Logo_ohne_Kreis.jpg"
            />
            <p className="px-2 text-xl">Heidekojoten</p>
          </a>
          <button
            aria-label={open ? "Open Menu" : "Close Menu"}
            className="xl:hidden h-10 w-10 p-1 rounded hover:bg-primary-500 focus:bg-primary-500  focus:outline-none duration-300"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <X className="h-full w-full" />
            ) : (
              <List className="h-full w-full" />
            )}
          </button>
        </div>
        <div
          className={`p-2 ${
            open ? "block" : "hidden"
          } xl:flex xl:items-center xl:justify-center`}
        >
          <NavLink to="/#home">Home</NavLink>
          <NavLink to="/#geschichte">Geschichte</NavLink>
          <NavLink to="/#mitgliedschaft">Mitgliedschaft</NavLink>
          <NavLink to="/#kontakt">Kontakt</NavLink>
          <NavLink to="https://forum.heidekojoten.de/">Forum</NavLink>
          <NavLink to="/downloads/Mitgliedsantrag.pdf">Mitgliedsantrag</NavLink>
        </div>
      </nav>
    </div>
  );
};
