import React from "react";
import Helmet from "react-helmet";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

export interface LayoutProps {
  title: string;
  description: string;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      <Helmet title={props.title}>
        <html lang="de" />
        <link rel="shortcut icon" href="/favicon.png" />
        <meta name="description" content={props.description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:site_name" content={props.title} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={props.title} />
        <meta property="twitter:description" content={props.description} />
      </Helmet>
      <Navbar />
      <main className="main w-screen">{props.children}</main>
      <Footer />
    </>
  );
};
