import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FooterLink: React.FC<{ to: string }> = ({ to, children }) => {
  return (
    <a
      href={to}
      className="block text-black text-lg hover:text-primary-700 duration-300"
    >
      {children}
    </a>
  );
};

export const Footer: React.FC = () => {
  return (
    <footer className="py-12 bg-primary-50">
      <div className="text-black container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-center">
        <div className="mt-6">
          <Link
            to="/#home"
            className="py-1 px-3 flex items-center justify-center rounded fill-current stroke-current hover:bg-primary-200 focus:bg-primary-200 focus:outline-none duration-300"
            aria-label="Home"
          >
            <StaticImage
              alt="Heidekojoten Wolf"
              className="h-10 w-10"
              objectFit="contain"
              src="../images/Logo_ohne_Kreis.jpg"
            />
            <p className="px-2 text-xl">Heidekojoten</p>
          </Link>
          <p className="px-3 text-center">
            Der BVB Fanclub für Soltau und Umgebung
          </p>
        </div>
        <div>
          <h3 className="text-lg mt-6 text-primary-900 font-semibold">
            Verein
          </h3>
          <FooterLink to="/#home">Home</FooterLink>
          <FooterLink to="/#geschichte">Geschichte</FooterLink>
          <FooterLink to="/#mitgliedschaft">Mitgliedschaft</FooterLink>
          <FooterLink to="/#kontakt">Kontakt</FooterLink>
        </div>
        <div>
          <h3 className="text-lg mt-6 text-primary-900 font-semibold">Links</h3>
          <FooterLink to="https://forum.heidekojoten.de/">Forum</FooterLink>
          <FooterLink to="/impressum">Impressum</FooterLink>
          <FooterLink to="/datenschutz">Datenschutzerklärung</FooterLink>
        </div>
        <div>
          <h3 className="text-lg mt-6 text-primary-900 font-semibold">
            Downloads
          </h3>
          <FooterLink to="/downloads/Mitgliedsantrag.pdf">
            Mitgliedsantrag
          </FooterLink>
        </div>
      </div>
    </footer>
  );
};
